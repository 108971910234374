.row-footer {
  background-color: #7371fc;
  padding-bottom: 20px;
  box-shadow: 5px 0 5px 0 #616161;
}

.text-footer {
  font-size: 18px;
  padding: 25px 5px 0px 5px;
  color: white;
  display: inline-block;
}
