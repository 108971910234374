.row-work {
  height: fit-content;
}

.personal-projects-title {
  font-weight: bold;
}

.portfolio-title,
.github-link {
  color: white;
}

.portfolio-title:hover,
.github-link:hover {
  color: lightgray;
}

.portfolio-img {
  width: 1000px;
  border: 5px solid lightgray;
  border-radius: 10px 10px 10px 10px;
  transition: 0.05s ease-out;
  -webkit-transition: 0.05s ease-out;
}

.portfolio-img:hover {
  width: 985px;
  transition: 0.05s ease-in;
  -webkit-transition: 0.05s ease-in;
}

.text-work {
  padding: 200px 80px 100px 80px;
}
