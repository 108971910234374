.column-skills {
  margin-top: 25px;
}

.text-skills {
  font-weight: bold;
  font-size: 64px;
}

@media (min-width: 40rem) {
  .text-skills {
    font-size: 36px;
  }
}

@media (min-width: 55rem) {
  .text-skills {
    font-size: 48px;
  }
}

@media (min-width: 60rem) {
  .text-skills {
    font-size: 52px;
  }
}

@media (min-width: 80rem) {
  .text-skills {
    font-size: 64px;
  }
}
