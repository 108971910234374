.nav {
  height: max-content;
  background-color: #7371fc;
  color: #f5efff;
  width: 100%;
  position: absolute;
  -webkit-box-shadow: 0 8px 6px -6px #616161;
  -moz-box-shadow: 0 8px 6px -6px #616161;
  box-shadow: 0 6px 5px -7px #616161;
}

.row {
  padding: 0px 5px 0px 10px;
}

.avataaar {
  margin: 5px;
  height: 35px;
  width: 35px;
  float: left;
}

.name {
  font-weight: bold;
  font-size: 30px;
  float: left;
}
