/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 24px;
  top: 12px;
  right: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #cdc1ff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a594f9;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  -webkit-box-shadow: -5px 0px 5px -3px #616161;
  -moz-box-shadow: -5px 0px 5px -3px #616161;
  box-shadow: -5px 0px 5px -3px #616161;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.title {
  color: #d4d4d4;
}

.menu-item {
  font-weight: bold;
  font-size: 24px;
  padding: 5px;
  color: #483993;
  border-bottom: 1px lightgray solid;
}

/* Styling of overlay */
.bm-overlay {
  background: #a594f9;
}
